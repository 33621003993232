import React from 'react'
import './header.scss'
export const Header = () => {


  return (
    <nav className="navbar">
      <div className="container">
        <h2 className='header-top-title'>Generate Your Short URL</h2>
      </div>
    </nav>


  )
}
