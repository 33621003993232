import React, { useEffect, useState } from "react";
import "./home.scss";
import linkImage from "../../image/pngwing.com.png";
import secrutyImage from "../../image/secruty.png";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import staticImage from "../../image/staticts.png";

export const HomePage = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("shortenedUrls")) || [];
    setData(storedData);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = e.target.url.value;
    if (url) {
      fetch("https://minni.uz/api/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      })
        .then((res) => res.json())
        .then((fetchedData) => {
          const updatedData = [...data, fetchedData];
          setData(updatedData);
          localStorage.setItem("shortenedUrls", JSON.stringify(updatedData));
        });
    } else {
      setOpen(true);
    }
  };

  const handleCopy = (url) => {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success("Copied!");
  };

  const deleteItem = (url) => {
    const newArr = data.filter((el) => el.url !== url);
    setData(newArr);
    localStorage.setItem("shortenedUrls", JSON.stringify(newArr));
  };


  const handledDownload = (qrcode) => {
    const a = document.createElement("a");
    a.href = qrcode;
    a.download = "qrcode.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <section className="Home-hero-section">
        <div className="Hero_Box">
          <div className="container">
            <ToastContainer />
            <div className="shorten_box">
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <div className="input-group input-group-lg shadow-lg">
                  <input
                    type="url"
                    spellCheck={false}
                    autoComplete="off"
                    autoCorrect="off"
                    id="long-url-input"
                    required="required"
                    placeholder="Enter a link to shorten it"
                    name="url"
                    className="form-control input-lg input-xlg"
                  />
                  <div className="input-group-append">
                    <button
                      style={{ backgroundColor: "#284243", color: "#fff" }}
                      aria-label="Create Short Link"
                      type="submit"
                      className="btn btn-primary btn-lg"
                    >
                      <span className="d-none d-md-inline-block">Shorten URL</span>
                    </button>
                  </div>
                </div>
              </form>
              {open && (
                <span style={{ color: "red" }}>
                  Siz url ni kiritishingiz zarur !
                </span>
              )}
              <p className="shorten-text">
                Minni.uz is a free tool to shorten URLs and generate short links
                URL shortener allows to create a shortened link making it easy
                to share
              </p>
              <div className="shortern_box-1">
                {data.map((el) => (
                  <div key={el.url} className="shortern-row">
                    <div className="shorten-url-box">
                      <div className="shorten-top">
                        <p className="shortern-url-text">{el.url}</p>
                      </div>
                      <div className="btn-group">
                        <button
                          className="btn-4"
                          onClick={() => handledDownload(el.qrcode)}
                        >
                          <i class="fa-solid fa-qrcode"></i>
                        </button>
                        <button
                          className="copy-btn"
                          onClick={() => handleCopy(el.url)}
                        >
                          <i class="fa-regular fa-clipboard"></i>
                        </button>
                        <button
                        className="delete-btn"
                        onClick={() => deleteItem(el.url)}
                      >
                        {" "}
                        <i class="fa-regular fa-trash-can"></i>
                      </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="shorten-content">
              <div className="shorten-inner-content">
                <img src={linkImage} alt="link-icon" />
                <h3>Shortened</h3>
                <p className="shorten-inner-content_text">
                  T.LY URL Shortener makes long links look cleaner and easier to
                  share! Add your own Custom Domains to personalize your brand!
                  Over 20 million monthly visitors trust T.LY. Easily create
                  trackable QR Codes.
                </p>
              </div>
              <div className="shorten-inner-content">
                <img src={secrutyImage} alt="link-icon" />
                <h3>Secure</h3>
                <p className="shorten-inner-content_text">
                  T.LY URL Shortener makes long links look cleaner and easier to
                  share! Add your own Custom Domains to personalize your brand!
                  Over 20 million monthly visitors trust T.LY. Easily create
                  trackable QR Codes.
                </p>
              </div>
              <div className="shorten-inner-content">
                <img src={staticImage} alt="link-icon" />
                <h3>Statistics</h3>
                <p className="shorten-inner-content_text">
                  With over 30,000,000 links shortened and tracked over
                  600,000,000 link clicks, T.LY lets you know where users are
                  coming from and is a click counter tool to track link
                  analytics. Just add a + at the end of any short URL to see
                  statistics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
